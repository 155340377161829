import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import contractAbi from "./abi.json";
import { ethers, BigNumber } from "ethers";
import Countdown from "react-countdown";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const ImageButton = styled.img`
width:150px;
cursor: pointer;
:hover {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
:active {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
`

export const StyledButton = styled.button`
  padding: 15px;
  border-radius: 5px;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  color: var(--secondary-text);
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 2px;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperHead = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  justify-content: center;
`;

export const StyledLogo = styled.img`
  width: 20px;
  @media (min-width: 767px) {
    width: 30px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.1);
  background-color: var(--accent);
  border-radius: 10px;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--primary);
  text-decoration: none;
`;

function App() {
  const Completionist = () => <span> MINT STARTED!</span>;
  let isSaleActive = false;
  let saleName = "SALE";
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  if(data.isActive ) {
      isSaleActive = true;
  }

  if(data.isActive) {
    saleName = "SALE STARTED"
  }

  async function claimNFTs () {
    console.log(data.totalSupply, data.isActive, data)
    if (data.isActive) {
      if(window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          CONFIG.CONTRACT_ADDRESS,
          contractAbi,
          signer
        );
        try {
          setFeedback(`Approve Transaction...`);
          setClaimingNft(true);
          var price = CONFIG.ETH_COST
          if(data.freeMintTracker < 1111 && data.balance < 1){
            if(mintAmount > 1) {
              price =  price * (mintAmount-1)
            } else {
              price = 0
            }
          } else {
            price = CONFIG.ETH_COST * mintAmount
          }
          console.log(price);
          const response = await contract.mint(BigNumber.from(mintAmount), { value: ethers.utils.parseEther(((price)).toString()) })
          const receipt = await response.wait()
          if(receipt) {
            console.log(receipt);
            setFeedback(
              ` Succesfully Minted ${CONFIG.NFT_NAME} visit opensea.io to see.`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          }
        } catch (err) {
          console.log(err);
          setFeedback(err.reason);
          setClaimingNft(false);
        }
      }
    } else {
      alert('No sale is started yet!')
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let maxMintAmount = 2;
    if (data.isActive) {
      maxMintAmount = data.walletLimit;
    }
    if (newMintAmount > maxMintAmount) {
      newMintAmount = maxMintAmount;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <ResponsiveWrapperHead flex={1} style={{ padding: 2 }} test>
          <a href={CONFIG.SCAN_LINK} style={{ height: "30px" }} target="_blank">
            <StyledLogo alt={"logo"} src={"/config/images/etherscan.png"} />
          </a>
          <s.SpacerSmall />
          <a href={CONFIG.MARKETPLACE_LINK} style={{ height: "30px" }} target="_blank">
            <StyledLogo alt={"logo"} src={"/config/images/opensea.png"} />
          </a>
          <s.SpacerSmall />
          <a href={CONFIG.TWITTTER_LINK} style={{ height: "30px" }} target="_blank">
            <StyledLogo alt={"logo"} src={"/config/images/twitter.png"} />
          </a>
        </ResponsiveWrapperHead>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={.3} jc={"center"} ai={"center"}>
          </s.Container>
          <s.Container flex={1} jc={"left"} ai={"left"}>
          <s.TextTitle
              style={{
                fontSize: 50,
                fontWeight: "900",
                color: "var(--primary-text)",
              }}
            >
              Mint Info
            </s.TextTitle>
            <s.TextTitle
                style={{
                  fontSize: "30px",
                  color: "var(--accent)",
                  backgroundColor: "var(--primary)",
                }}
              >
                Starts in:- 
              <Countdown date={new Date('2023-03-18T13:30:00.896Z').getTime()}>
                <Completionist />
              </Countdown>
            </s.TextTitle>
            <s.SpacerLarge />
            <s.SpacerLarge />
            <s.TextTitle
                style={{ fontWeight: 700, fontSize: 30, textAlign: "left", color: "var(--secondary-text)" }}
              >
                Sale Date
              </s.TextTitle>
              <s.TextDescription
                style={{ textAlign: "left", color: "var(--secondary-text)" }}
              >
                18/03/2023
            </s.TextDescription>
            <s.SpacerSmall />
            <s.TextTitle
                style={{ fontWeight: 700, fontSize: 30, textAlign: "left", color: "var(--secondary-text)" }}
              >
                Supply
              </s.TextTitle>
              <s.TextDescription
                style={{ textAlign: "left", color: "var(--secondary-text)" }}
              >
                {CONFIG.MAX_SUPPLY}
            </s.TextDescription>
            <s.SpacerSmall />
            <s.TextTitle
                style={{ fontWeight: 700, fontSize: 30, textAlign: "left", color: "var(--secondary-text)" }}
              >
                Price
              </s.TextTitle>
              <s.TextDescription
                style={{ textAlign: "left", color: "var(--secondary-text)" }}
              >
                {CONFIG.DISPLAY_COST}e/NFT
            </s.TextDescription>
            <s.SpacerSmall />
            <s.TextTitle
                style={{ fontWeight: 700, fontSize: 30, textAlign: "left", color: "var(--secondary-text)" }}
              >
                Allowance
              </s.TextTitle>
              <s.TextDescription
                style={{ textAlign: "left", color: "var(--secondary-text)" }}
              >
                5 per wallet MAX
            </s.TextDescription>
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={1}
            jc={"top"}
            ai={"center"}
            style={{
              backgroundColor: "var(--secondary)",
              padding: 24,
              borderRadius: 10,
              border: "2px solid var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.3)",
              background: "rgba(--secondary)",
              backdropFilter: "saturate(180%) blur(6px)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {saleName}
            </s.TextTitle>
            <hr class="rounded" style={{width: "100%", color: "#fff"}}/>
            <ResponsiveWrapper >
              <s.Container flex={1} jc={"center"} ai={"center"}>
                <s.TextDescription style={{color: "var(--accent-text)"}}>
                  Total Supply- 2222
                </s.TextDescription>
                <s.TextDescription style={{color: "var(--accent-text)"}}>
                  Max Mint 5 per Wallet
                </s.TextDescription>
                <StyledLogo
                  style={{ width: "150px" }}
                  src={'/config/images/logo.png'}>
                </StyledLogo>
              </s.Container>
            </ResponsiveWrapper>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Check opensea for {CONFIG.NFT_NAME}.
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink style={{border: "1px solid --primary", padding: "10px", borderRadius: "5px"}} target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.SpacerXSmall />
                {blockchain.account === "" || blockchain.smartContract === null || !isSaleActive ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.account == null ? 'Connect to the '+CONFIG.NETWORK.NAME+' network' : "Mint is not started yet" }
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      {blockchain.account == null ? "Connect" : "Connected"}
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "MINTING..." : "MINT"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
          </s.Container>
          <s.Container flex={.3} jc={"center"} ai={"center"}>
          </s.Container>
        </ResponsiveWrapper>
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          {
            <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
            >
              Dicky Doodles
            </s.TextDescription>
           }
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
